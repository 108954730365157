













import DynamicFieldMixin from "../mixins/dynamic_field"

import Select from './collection/select'
import Boxes from './collection/boxes'

export default {
  mixins: [DynamicFieldMixin]

  components: {Select, Boxes}

  computed:
    viewAsComponent: ->
      if @field.view_as == 'select'
        'Select'
      else
        'Boxes'
}
