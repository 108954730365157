DraftButtonMixin = {
  props:
    canSaveDraft:
      type: Boolean
      default: false

  computed:
    labelSaveDraftButton: ->
      @$I18n('helpers.submit.pim_idea.save_draft')

  methods:
    clickSaveDraftButton: ->
      @$emit('clickSaveDraft')
}

export default DraftButtonMixin
