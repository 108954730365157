import CommonMixin from './common'

OTHER_KEY_STRING = 'other:'

DynamicField = {
  mixins: [CommonMixin]

  computed:
    isOtherValueSelected: ->
      @field.isValueOther()

    fieldValueOtherClear:
      get: ->
        @field.getValueOtherClear()
      set: (v) ->
        @field.setValueOtherClear(v)

    fieldValueClear: ->
      @field.getValueClear()

  methods:
    getOtherKeyString: ->
      @field.getOtherKeyString()

  watch:
    isOtherValueSelected: (newValue, oldValue) ->
      @$emit('otherValueSelected', {selected: newValue})
}

export default DynamicField
