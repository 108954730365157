

















import CommonMixin from "../mixins/common"

import FormPartInputGroup from '../../../../../components/forms/form_part_input_group'

export default {
  mixins: [CommonMixin]

  components: {FormPartInputGroup}

  props:
    coauthorsInputId:
      type: String

  mounted: ->
    @initUserSelect()

  computed:
    inputName: ->
      "pim_idea[coauthor_ids][]"

    coauthorsAmountOverLimit: ->
      true

  methods:
    initUserSelect: ->
      @appendOptionToSelect()
      selectOpts = @userSelectOptions()
      selectOpts.maximumSelectionLength = @field.max_amount
      selectOpts.maximumSelectionLengthMessage = 'Вы добавили максимальное число соавторов'
      new window.Agiki.Common.Select2RemoteInput($(@$refs.userSelect), selectOpts)

    appendOptionToSelect: ->
      return unless @field.value?

      return if @field.value.length == 0

      @field.value.forEach (item) =>
        element = document.createElement('option');
        element.setAttribute('value', item.id)
        element.setAttribute('selected', 'selected')
        element.innerText = item.label

        @$refs.userSelect.appendChild(element)

    userSelectOptions: ->
      url: @field.url
      multiple: true
}
