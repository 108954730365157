





























































  import CommonMixin from "../mixins/common"
  import axios from 'axios'

  import FormPartInputGroup from '../../../../../components/forms/form_part_input_group'

  DATA_MIXIN = {
    data: ->
      recalcCoefficientKey: 0
      showCoauthors: null
      availableCoauthorsEmpty: false
      coauthors: []
  }

  export default {
    components: {FormPartInputGroup},
    mixins: [CommonMixin, DATA_MIXIN]

    props:
      coauthorsInputId:
        type: String
      maxAmount:
        type: Number

    computed:
      coauthorIds: ->
        @coauthors.map (c) -> c.id

      inputName: ->
        'pim_idea[coauthors_data][]'

      defaultCoefficientOptions: ->
        defaultOpts = Array.apply(null, {length: 19})
        defaultOpts = defaultOpts.map((v, i) -> ((i+1) * 5))
        defaultOpts

      authorsCoefficient: ->
        if @coauthors.length > 0
          @coauthors[0].coefficient
        else
          return 100

      coauthorsAmountOverLimit: ->
        @coauthors.length > @maxAmount

      canNotAddCoauthor: ->
        @coauthorsAmountOverLimit || @authorsCoefficient <= 5 || @availableCoauthorsEmpty

      canNotAddCoauthorMsg: ->
        if @availableCoauthorsEmpty
          'К сожалению, в перечне соавторов больше нет зарегистрированных сотрудников Вашего предприятия'
        else
          if @coauthorsAmountOverLimit
            'Вы добавили максимальное число соавторов'
          else
            'Больше нельзя добавить соавтора, автор идеи имеет минимальный возможный коэффицент участия'

    mounted: ->
      @inputReset()

    updated: ->
      @updateAuthorCoefficient()
      @initStyleSelects()

    methods:
      coauthorsCoefficientSum: ->
        res = 0
        if @coauthors.length > 1
          @coauthors.forEach (coauthor, index) =>
            return if index == 0
            c = parseInt(coauthor.coefficient)
            res += c
        res

      checkAvailableCoauthors: ->
        axios.request(@checkAvailableCoauthorsRequest()).then(@checkAvailableCoauthorsSuccess.bind(@))

      checkAvailableCoauthorsRequest: ()->
        url: @coauthorsUrl()
        method: 'get'

      checkAvailableCoauthorsSuccess: (response) ->
        data = response.data
        if data.results?
          if data.results.length == 0
            @availableCoauthorsEmpty = true
          else
            @availableCoauthorsEmpty = false

      initShowCoauthors: ->
        @showCoauthors = @coauthors.length > 0

      initUserSelect: ->
        new window.Agiki.Common.Select2RemoteInput($(@$refs.userSelect), @userSelectOptions())

      coauthorsUrl: ->
        "#{@field.url}?coauthors=#{@coauthorIds}"

      userSelectOptions: ->
        url: @coauthorsUrl
        close: @handlerUserSelectChange.bind(@)

      coauthorInputValue: (coauthor, index) ->
        JSON.stringify({id: coauthor.id, coefficient: coauthor.coefficient})

      showRemoveCoauthors: (index) ->
        index != 0

      showAuthorCoefficient: (index) ->
        index == 0

      removeCoauthor: (coauthorId) ->
        @coauthors = this.coauthors.filter (c) ->
          return c.id != coauthorId        
        @checkAvailableCoauthors()

      updateAuthorCoefficient: ->
        author = null
        author = @coauthors[0] if (@coauthors.length > 0)
        if (author)
          new_coefficient = 100 - @coauthorsCoefficientSum()
          new_coefficient = 0 if new_coefficient < 0
          author.coefficient = new_coefficient

        @recalcCoefficientKey += 1

        true

      coauthorCoefficientOptions: (coauthor) ->
        maxCoefficient = parseInt(coauthor.coefficient) + parseInt(@authorsCoefficient)
        res = @defaultCoefficientOptions.filter (i) ->
          i < maxCoefficient

        res

      handlerUserSelectChange: (value) ->
        return if @coauthorsAmountOverLimit
        return if !value
        c = @coauthors.find((coauthor) -> (parseInt(coauthor.id) == parseInt(value.id) ))
        return if c?
        @coauthors.push({
          id: value.id
          name: value.label
          coefficient: 5
        })
#        @fieldValue = @coauthors
        @checkAvailableCoauthors()

      coefficientValue: (coauthor) ->
        "#{coauthor.coefficient}%"

      initStyleSelects: ->
        @$el.querySelectorAll("[data-coefficient-coauthor-id]").forEach (el) =>
          $(el).styleSelect('reinit')

      inputReset: ->
        @coauthors = JSON.parse(JSON.stringify(@fieldValue))
        @initShowCoauthors()        

    watch:        
      showCoauthors: (newValue, oldValue) ->
        return unless newValue
        @$nextTick =>
          @initUserSelect()

      coauthors: () ->
        @updateAuthorCoefficient()
        @$emit('coauthorsChanged', @coauthors)        
        @$forceUpdate()        
  }
