


















import CommonMixin from './mixins/common'

import FormPartInputGroup from '../../../../components/forms/form_part_input_group'

export default {
  mixins: [CommonMixin]

  components: {FormPartInputGroup}

  mounted: ->
    window.autosize($(@$refs.titleInput))

  computed:
    inputId: ->
      "#{@formNamespace}_pim_idea_title"

  watch:
    fieldValue: ->
      @checkSwearWords()


}
