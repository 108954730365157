CancelButton = {
  computed:
    cancelButtonClasses: ->
      "pim-button--#{@category}": true
      'js-pim-idea-form-hide': @isModeEdit
      'js-hide-idea-form': @isModeNew

    cancelButtonLabel: ->
      @$I18n('forms.buttons.cancel')

  methods:
    clickCancelButton: ->
      @$emit('clickcancel')
}

export default CancelButton
