










import DynamicFieldMixin from "../../mixins/dynamic_field"
import CollectionBoxes from "../common/collection_boxes"

export default {
  mixins: [DynamicFieldMixin]

  components: {CollectionBoxes}

  computed:
    fieldValues: ->
      items = ((@field.values || {})[@$pageLang()] || [])
      items.map (i) ->
        label: i
        value: i
}
