SubmitButton = {
  props:
    formNotValid:
      type: Boolean
      default: false

  data: ->
    isSubmit: false

  computed:
    buttonDisabled: ->
      @isSubmit || @formNotValid

    submitButtonClasses: ->
      disabled: @buttonDisabled

    disableSubmitButton: ->
      @buttonDisabled

    labelSubmitButton: ->
      if @isModeNew
        @$I18n('helpers.submit.pim_idea.create')
      else
        @$I18n('helpers.submit.pim_idea.update')

    labelSubmitDisabledByStage: ->
      @$I18n().activemodel.attributes['pim/project'].idea_adding_disabled_by_user_fields

  methods:
    submitFinish: ->
      @isSubmit = false

    submitStart: ->
      @isSubmit = true

    clickSubmitButton: ->
      @$emit('clicksubmit')
}

export default SubmitButton
