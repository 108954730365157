



















import CommonFileMixin from "../mixins/common_file"

export default {
  mixins: [CommonFileMixin]

  computed:
    fileAllowedFileTypes: ->
      @field.file?.allowedFileTypes || ['png', 'jpg', 'jpeg']
}
