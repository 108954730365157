UpdateSimilarBlock = {
  data: ->
    containerWithSimilarOffsetHeight: 0
    containerWithSimilarOffsetHeightInterval: null

  methods:
    registerChangeContainerOffsetHeight: ->
      return if @containerWithSimilarOffsetHeightInterval?
      @containerWithSimilarOffsetHeightInterval = setInterval(@containerWithSimilarOffsetHeightIntervalHandler.bind(@), 50)

    destroyChangeContainerOffsetHeight: ->
      return unless @containerWithSimilarOffsetHeightInterval?
      clearInterval(@containerWithSimilarOffsetHeightInterval)

    containerWithSimilarOffsetHeightIntervalHandler: ->
      parentElement = @containerWithSimilar()
      return unless parentElement?

      @containerWithSimilarOffsetHeight = parentElement.offsetHeight

    containerWithSimilar: ->
      el = @$el.closest('.pim-idea-form--with-similar-ideas')
      unless el.parentElement # autodestroy
        @destroyChangeContainerOffsetHeight
        return
      if el == @$el
        elP = el.parentElement.closest('.pim-idea-form--with-similar-ideas')
        el = elP if elP?
      el

  watch:
    containerWithSimilarOffsetHeight: ->
      containerElement = @$el.closest('.pim-idea-form-container')
      return unless containerElement?

      elements = containerElement.querySelectorAll('[data-similar]')
      for element in elements
        element.style.height = "#{@containerWithSimilarOffsetHeight}px"
}

export default UpdateSimilarBlock
