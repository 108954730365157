import DynamicFieldCommonMixin from "../../../../../components/dynamic_fields/form/mixins/common"

CommonMixin = {
  mixins: [DynamicFieldCommonMixin]
  computed:
    defaultPlaceholder: ->
      @$I18n("simple_form.placeholders.#{@fieldNamePrefix}.#{@field.name}") || ''
    placeholder: ->
      if @field.placeholder && @field.placeholder.constructor != Object && @field.placeholder.trim().length
        @field.placeholder
      else if @field.placeholder.constructor == Object && Object.keys(@field.placeholder).length == 0
        @defaultPlaceholder
}

export default CommonMixin
