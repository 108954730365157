















import CommonMixin from "./mixins/common"
import RedactorResetMixin from "../../../../components/forms/mixins/redactor_reset"

import FormPartInputGroup from '../../../../components/forms/form_part_input_group'

export default {
  mixins: [CommonMixin, RedactorResetMixin]

  components: {FormPartInputGroup}

  mounted: ->
    setTimeout( =>
      @initRedactor()
    , 500)

  computed:
    inputId: ->
      "#{@formNamespace}_pim_idea_content"

    redactorOptions: ->
      o = @field.redactorOptions || {}
      Object.assign({}, o, {
        changeCallback: @redactorChangeCallbackHandler.bind(@)
      })

  methods:
    initRedactor: ->
      document.createRedactor($(@$refs.redactor), @redactorOptions)

    inputReset: ->
      @redactorReset()

    redactorChangeCallbackHandler: (value) ->
      if(@isRedactorEmptyValue(value))
        @fieldValue = ''
      else
        @fieldValue = value

  watch:
    fieldValue: ->
      @checkSwearWords()
}
