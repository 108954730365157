ModeView = {
  props:
    mode:
      type: String
      default: 'new'
      validator: (v) ->
        ['new', 'edit'].indexOf(v) != -1

  computed:
    isModeNew: ->
      @mode == 'new'

    isModeEdit: ->
      @mode == 'edit'
}

export default ModeView
