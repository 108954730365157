









export default {
  props:
    errorMessages: Array

  computed:
    showBlock: ->
      @errorMessages? && @errorMessages.length > 0
}
