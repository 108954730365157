















































VIEW_AS_RADIO = 'radio_buttons'
VIEW_AS_CHECKBOX = 'check_boxes'
VIEW_AS = [VIEW_AS_RADIO, VIEW_AS_CHECKBOX]

import InputIdNameGeneratorsMixin from "../../mixins/input_id_name_generators"

export default {
  mixins: [InputIdNameGeneratorsMixin]

  props:
    items:
      type: Array
      default: ->
        []
      validator: (value) ->
        return false unless Array.isArray(value)
        value.every (v) ->
          v.label? && v.value?

    viewAs:
      type: String
      required: true
      validator: (value) ->
        VIEW_AS.indexOf(value) != -1

    hasOtherValue:
      type: Boolean
      default: false

    fieldName:
      type: String
      require: true

    fieldNamePrefix:
      type: String
      default: null

    value:
      type: [String, Number, Array]
    
    required:
      type: Boolean
      default: false

  data: ->
    internalValue: @value

  computed:
    internalItems: ->
      if @hasOtherValue
        items = Object.assign([], @items)
        items.push {
          label: @otherLabel,
          value: 'other:'
        }
        items
      else
        @items

    otherLabel: ->
      @$I18n('dynamic_fields.collection.other_value')

    inputName: ->
      name = @generateInputName()
      if @viewAsRadio
        name
      else
        "#{name}[]"

    viewAsRadio: ->
      @viewAs == VIEW_AS_RADIO

    viewAsCheckbox: ->
      @viewAs == VIEW_AS_CHECKBOX

    labelClass: ->
      'input--radio': @viewAsRadio
      'input--checkbox': @viewAsCheckbox

  methods:
    generateKey: (item) ->
      "#{@fieldName}__#{item.value}__#{@._uid}"

  watch:
    internalValue: (newValue) ->
      @$emit('input', newValue)
}
