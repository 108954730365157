








import DynamicFieldMixin from "../mixins/dynamic_field"

import ViewAsCombinatorial from './criterion/combinatorial'
import ViewAsNumericalScale from './criterion/numerical_scale'

export default {
  mixins: [DynamicFieldMixin]

  components: {ViewAsCombinatorial, ViewAsNumericalScale}

  computed:
    viewAsComponent: ->
      if @field.view_as == 'combinatorial'
        'ViewAsCombinatorial'
      else if @field.view_as == 'numerical_scale'
        'ViewAsNumericalScale'
}
