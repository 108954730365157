import DynamicField from './dynamic_field'
import DynamicFieldGroup from './dynamic_field_group'

class DynamicFields
  constructor: (containerFields = null) ->
    @__fields = []
    @containerFields = containerFields
    @__wrapper = true

  push: (field) ->
    @__fields.push(field)

  forEach: (callback) ->
    @__fields.forEach(callback)

  find: (callback) ->
    @__fields.find(callback)

  findIndex: (callback) ->
    @__fields.findIndex(callback)

  filter: (callback) ->
    @__fields.filter(callback)

  findField: (fieldName) ->
    if @containerFields
      @containerFields.findField(fieldName)
    else
      @__findField(fieldName, @__fields)

  hasGroupFields: ->
    @__fields.some (field) ->
      field.isGroup()

  __findField: (fieldName, fields) ->
    r = null
    if typeof(fields.some) == 'undefined' && fields.__fields
      fields = fields.__fields
    fields.some (field) =>
      if field.isGroup()
        r = @__findField(fieldName, field.fields)
      else
        r = field if field.name == fieldName
      r?
    r

  getFields: ->
    @__fields

  getFieldsFlatten: ->
    @__fieldsFlatten = []

    @__fields.forEach (field) =>
      @__fieldsFlatten.push(field)
      if field.isGroup()
        @__fieldsFlatten.push(...field.fields.getFields()) if field.fields?

    @__fieldsFlatten

  resetValueToInitial: ->
    @getFieldsFlatten().forEach (field) ->
      field.resetValueToInitial()

  setErrorMessages: (fieldName, errorMessages) ->
    f = @__findField(fieldName, @__fields)
    if f?
      f.setErrorMessages(errorMessages)

  resetErrorMessages: (fieldName = null) ->
    if fieldName?
      @setErrorMessages(fieldName, [])
    else
      @getFieldsFlatten().forEach (field) ->
        field.resetErrorMessages()

  @build: (fields, containerFields = null) ->
    return fields if fields.__wrapper

    result = new DynamicFields()
    fields.forEach (field) ->
      if field.type == 'group'
        result.push(DynamicFieldGroup.build(field, result))
      else
        result.push(DynamicField.build(field, result))
    window.Vue.observable(result)    

export default DynamicFields
