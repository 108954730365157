



















import DynamicFieldMixin from '../mixins/dynamic_field'

OTHER_KEY_STRING = 'other:'

export default {
  mixins: [DynamicFieldMixin]

  props: {
    initValue:
      type: String
      default: OTHER_KEY_STRING
  }

  computed:
    inputId: ->
      parts = []
      parts.push(@formNamespace) if @formNamespace? && @formNamespace.trim() != ''
      parts.push("#{@fieldNamePrefix}_dynamic_field_values_other_values_#{@field.name}")
      parts.join('_')

    inputName: ->
      "#{@fieldNamePrefix}[dynamic_field_values][other_values][#{@field.name}]"

    inputValueWithOther:
      get: ->
        @field.getValueWithOther()
      set: (v) ->
        @field.setValueWithOther(v)
}
