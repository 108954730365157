EMPTY_VALUE = '<p>&#8203;</p>'

RedactorReset = {
  methods:
    redactorReset: ->
      Object.values(@$refs).forEach (ref) ->
        redactorInstance = $.data(ref, 'redactor');
        if redactorInstance?
          redactorInstance.code.set(EMPTY_VALUE)

    isRedactorEmptyValue: (value) ->
      return true unless value?
      value.toString().trim() == EMPTY_VALUE.trim()
}

export default RedactorReset
