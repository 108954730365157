

















import CommonMixin from './mixins/common'

import FormPartRequired from '../../../components/forms/form_part_required'
import FormPartErrors from '../../../components/forms/form_part_errors'

export default {
  mixins: [CommonMixin]

  components: {FormPartRequired, FormPartErrors}
}
