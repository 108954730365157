AdviseButton = {
  props:
    advisesUrl:
      type: String
      default: ''

  computed:
    canShowAdvise: ->
      !!@advisesUrl
    adviseButtonLabel: ->
      @$I18n('advises.show_advises')
    adviseButtonTooltip: ->
      @$I18n('advises.show_advises_hint')
  methods:
    adviseButtonClick: (event)->
      document.____showAdviseToggle($(event.target))
}

export default AdviseButton
