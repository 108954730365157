







import DynamicField from './dynamic_field'
import DynamicFieldStaticMock from './fields/static_mock'

import ModelDynamicFields from '../libs/dynamic_fields'

export default {
  components: {
    DynamicField,
    DynamicFieldStaticMock
  }

  props:
    projectId:
      type: [String, Number]

    fields:
      type: Array
      required: true

    fieldNamePrefix:
      type: String

    formNamespace:
      type: String

    dynamicFieldNamespace:
      type: String

    mode:
      type: String
      default: 'new'

  beforeCreate: ->
    @$options.components.GroupFields = require('./group_fields').default

  computed:
    __fields: ->
      ModelDynamicFields.build(@fields).getFields()

  methods:
    fieldComponentBase: (field) ->
      if field.isStatic()
        @$dynamicFields().staticComponentId(@dynamicFieldNamespace, field.name)
      else if field.isGroup()
        "GroupFields"
      else
        "DynamicField"

    fieldComponent: (field) ->
      @fieldComponentBase(field)

    fieldComponentId: (field) ->
      if field.isStatic()
        "static__#{field.name}"
      else
        field.name

    fieldBind: (field) ->
      r = {
        key: @fieldComponentId(field)
        is: @fieldComponent(field)
        projectId: @projectId
        field: field
        fieldNamePrefix: @fieldNamePrefix
        formNamespace: @formNamespace
      }

      if field.isGroup()
        r.dynamicFieldNamespace = @dynamicFieldNamespace

      r

}
