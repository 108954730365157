import DynamicFields from "../../dynamic_fields/libs/dynamic_fields"

DynamicFieldsModel = {
  props:
    fields:
      type: Array
      default: []

  computed:
    __fieldsModel: ->
      DynamicFields.build(@fields)

    __fields: ->
      @__fieldsModel.getFields()

    hasFieldsGroups: ->
      @__fieldsModel.hasGroupFields()

    fieldsFlatten: ->
      @__fieldsModel.getFieldsFlatten()

  methods:
    beforeMountDynamicFieldsModel: ->
      @__fieldsModel
}

export default DynamicFieldsModel
