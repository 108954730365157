import DynamicFields from '../../dynamic_fields/form/dynamic_fields'

FieldComponents = {
  components: {DynamicFields}

  props:
    dynamicFieldNamespace:
      type: String
      default: ''
}

export default FieldComponents
