import InputIdNameGeneratorsMixin from './input_id_name_generators'

CommonMixin = {
  mixins: [InputIdNameGeneratorsMixin]

  props:
    projectId: [Number, String]
    field: Object
    formNamespace: String
    fieldNamePrefix:
      type: String
      require: true
    mode:
      type: String
      default: 'new'
      validator: (v) ->
        ['new', 'edit'].indexOf(v) != -1

  methods:
    checkSwearWords: ->
      if @field.check_swear_words
        @fildChecked = false
        checker_res = Agiki.Common.SwearWordsChecker.check(@fieldValue, @projectId)
        checker_res.then (result) =>
          @fildChecked = true
          if !(@fieldValid = !result.isContains())
            @field.setErrorMessages('В тексте присутсвует нецензурная лексика')

  computed:
    inputId: ->
      @generateInputId()

    inputName: ->
      @generateInputName()

    label: ->
      @field.label[@$pageLang()]

    description: ->
      @field.description[@$pageLang()]

    hint: ->
      @field.hint[@$pageLang()]

    placeholder: ->
      @field.placeholder[@$pageLang()]

    initialValue: ->
      @field.initialValue

    isModeNew: ->
      @mode == 'new'

    isModeEdit: ->
      @mode == 'edit'

    hasError: ->
      @field.errorMessages? && @field.errorMessages.length > 0

    fieldContainerClass: ->
      r = {}
      if @field.dictionary?
        r.fieldset = @field.dictionary.type == 'Dictionaries::Tree'
      if @field.type == 'criterion'
        r['input-group--column'] = @field.as_labels
      r

    fieldLabelContainerClass: ->
      r = {}
      if @field.dictionary?
        r.fieldset__legend = @field.dictionary.type == 'Dictionaries::Tree'
      r

    fieldValue:
      get: ->
        try
          @field.getValue()
        catch e
          e
      set: (v) ->
        @field.setValue(v)

    fieldValid:
      get: ->
        try
          @field.valid
        catch e
          e
      set: (v) ->
        @field.valid = v

    fildChecked:
      get: ->
        try
          @field.checked
        catch e
          e
      set: (v) ->
        @field.checked = v

  watch:
    fieldValid:  ->
      if @fieldValid
        @field.resetErrorMessages()        
}

export default CommonMixin
