import IdeaForm from "../javascripts/pim/ideas/form_wrapper"
import IdeaFormWithCategories from "../javascripts/pim/ideas/form_with_categories"

import DynamicFieldIdeaCoauthors from '../javascripts/pim/ideas/form_fields/coauthors'
import DynamicFieldIdeaContent from '../javascripts/pim/ideas/form_fields/content'
import DynamicFieldIdeaTitle from '../javascripts/pim/ideas/form_fields/title'

Vue.$dynamicFields().registerStaticComponent('Idea', 'Coauthors', DynamicFieldIdeaCoauthors)
Vue.$dynamicFields().registerStaticComponent('Idea', 'Content', DynamicFieldIdeaContent)
Vue.$dynamicFields().registerStaticComponent('Idea', 'Title', DynamicFieldIdeaTitle)

components = [
  {selector: "[data-vue-component='pim-idea-form']", component: IdeaForm}
  {selector: "[data-vue-component='pim-idea-form-with-categories']", component: IdeaFormWithCategories}
]

window.Agiki ||= {}
window.Agiki.Pim ||= {}
window.Agiki.Pim.IdeaForm ||= {}

window.Agiki.Pim.IdeaForm.registerNewRender = (scope = document) =>
  window.Agiki.Common.VueComponentInitializer.initComponents(components: components, scope: scope)

import ExpertiseReportForm from '../javascripts/expertise/form_wrapper'

import DynamicFieldExpertReportExpertiseStatus from "../javascripts/expertise/form_fields/expertise_status"
import DynamicFieldExpertReportComment from "../javascripts/expertise/form_fields/comment"

Vue.$dynamicFields().registerStaticComponent('ExpertReport', 'ExpertiseStatus', DynamicFieldExpertReportExpertiseStatus)
Vue.$dynamicFields().registerStaticComponent('ExpertReport', 'Comment', DynamicFieldExpertReportComment)

expertiseComponents = [
  {selector: "[data-vue-component='expertise-report-form']", component: ExpertiseReportForm}
]

window.Agiki.Expertise ||= {}
window.Agiki.Expertise.Form ||= {}
window.Agiki.Expertise.Form.registerNewRender = (scope = document, additionData = {}) =>
  dataChangerHandler = (data) =>
    data.callerElement = additionData.callerElement if additionData.callerElement?

  window.Agiki.Common.VueComponentInitializer.initComponents(components: expertiseComponents, scope: scope, dataChanger: dataChangerHandler)

document.addEventListener 'DOMContentLoaded', =>
  document.querySelectorAll("[data-vue-store]").forEach (element) =>
#    payload = JSON.parse(element.dataset['vueStore'])
#    store.commit('initValues', payload)

  window.Agiki.Pim.IdeaForm.registerNewRender(document)
  window.Agiki.Expertise.Form.registerNewRender(document)