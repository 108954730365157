









import DynamicFieldMixin from "../mixins/dynamic_field"

export default {
  mixins: [DynamicFieldMixin]
}
