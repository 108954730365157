

















import CommonFileMixin from "../mixins/common_file"

export default {
  mixins: [CommonFileMixin]
  
  computed:
    inputName: ->
      "#{@fieldNamePrefix}[dynamic_field_values][#{@field.name}][]"
}
