OTHER_KEY_STRING = 'other:'

class DynamicField
  constructor: (containerFields) ->
    @containerFields = containerFields
    @__wrapper = true
    @__visible = null
    @value = null
    @initialValue = null
    @errorMessages = []
    @__valueChanger = []

  isGroup: ->
    @type == 'group'

  isStatic: ->
    @type.startsWith('static')

  getValue: ->
    if Array.isArray(@value)
      @value.filter((v) -> v?)
    else
      @value

  setValue: (v) ->
    @value = v
    @callValueChanger()
    @value

  subValueChanger: (callback) ->
    @__valueChanger.push(callback)

  callValueChanger: ->
    @__valueChanger.forEach (callback) ->
      callback()

  getValueOtherClear: ->
    if @isValueOther()
      if Array.isArray(@getValue())
        @getValue().map (v) ->
          if v? && v.toString().startsWith(OTHER_KEY_STRING)
            OTHER_KEY_STRING
          else
            v
      else
        OTHER_KEY_STRING
    else
      @getValue()

  setValueOtherClear: (v) ->
    if Array.isArray(@getValue()) && @isValueOther()
      newV = v.map (_v) =>
        return _v unless _v?
        if _v.toString().startsWith(OTHER_KEY_STRING)
          fv = @getValue().find((fv) -> (fv.toString().startsWith(OTHER_KEY_STRING)))
          if fv?
            fv
          else
            _v
        else
          _v
      @setValue(newV)
    else
      @setValue(v)

  getValueClear: ->
    if @isValueOther()
      if Array.isArray(@getValue())
        @getValue()
      else
        @getValue().toString().replace(OTHER_KEY_STRING, '')
    else
      @getValue()

  getValueWithOther: ->
    if Array.isArray(@getValueClear())
      v = @getValueClear().find (v) =>
        v.toString().startsWith(OTHER_KEY_STRING)
      if v?
        v.toString().replace(OTHER_KEY_STRING, '')
      else
        ''
    else
      @getValueClear()

  setValueWithOther: (v) ->
    if Array.isArray(@getValue())
      vIndex = @getValue().findIndex((v) => v.toString().startsWith(OTHER_KEY_STRING))
      if vIndex >= 0
        window.Vue.set(@getValue(), vIndex, "#{@getOtherKeyString()}#{v.toString()}")
      else
        @getValue().push("#{@getOtherKeyString()}#{v.toString()}")
    else
      @setValue("#{@getOtherKeyString()}#{v.toString()}")


  isValueOther: ->
    DynamicField.isValueOther(@getValue())

  getOtherKeyString: ->
    OTHER_KEY_STRING

  sourceField: ->
    return null unless @source_field_name?
    return null if @source_field_name.trim() == ''
    @__sourceField ||= @findField(@source_field_name)

  isVisible: ->
    @calcVisible() unless @__visible?

    @__visible

  calcVisible: ->
    if  @sourceField()
      @sourceFieldValueChangerHandler()

      @sourceField().subValueChanger(@sourceFieldValueChangerHandler.bind(@))
    else
      @__visible = true

  getSourceFieldValues: ->
    return @source_field_values unless @source_field_values?
    return @source_field_values if Array.isArray(@source_field_values)
    @__sourceFieldValues ||= @source_field_values.split(',')

  sourceFieldValueChangerHandler: ->
    v = null
    if Array.isArray(@sourceField().getValue())
      v = @sourceField().getValue().some (v) =>
        if Array.isArray(@getSourceFieldValues())
          @getSourceFieldValues().some (sv) =>
            v.toString() == sv.toString()
        else
          v.toString() == @getSourceFieldValues().toString()
    else
      if @sourceField().getValue()?
        if Array.isArray(@getSourceFieldValues())
          @getSourceFieldValues().some (sv) =>
            @sourceField().getValue().toString() == sv.toString()
        else
          v = @sourceField().getValue().toString() == @getSourceFieldValues().toString()
      else
        v = false

    @__visible = v

  findField: (fieldName) ->
    @containerFields.findField(fieldName)

  resetValueToInitial: ->
    if @initialValue?
      @value = JSON.parse(JSON.stringify(@initialValue))
    else
      @value = null

  setErrorMessages: (errorMessages) ->
    if Array.isArray(errorMessages)
      @errorMessages = errorMessages
    else
      @errorMessages = [errorMessages]

  resetErrorMessages: ->
    @setErrorMessages([])

  __fillInitialValue: ->
    if @value?
      @initialValue = JSON.parse(JSON.stringify(@value))
    else
      @initialValue = null

  @build: (field, containerFields) ->
    if field.__wrapper
      field
    else
      r = Object.assign(new DynamicField(containerFields), field)
      r.__fillInitialValue()
      window.Vue.observable(r)

  @isValueOther: (value) ->
    return false unless value?
    if Array.isArray()
      value.some((v) -> (v.toString().startsWith(OTHER_KEY_STRING)))
    else
      value.toString().startsWith(OTHER_KEY_STRING)

  @getOtherKeyString: ->
    OTHER_KEY_STRING

export default DynamicField
