import { render, staticRenderFns } from "./combinatorial.vue?vue&type=template&id=2150f3b0&"
import script from "./combinatorial.vue?vue&type=script&lang=coffee&"
export * from "./combinatorial.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports