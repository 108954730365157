import DynamicField from './dynamic_field'
import DynamicFields from './dynamic_fields'

class DynamicFieldGroup extends DynamicField
  constructor: (containerFields) ->
    super(containerFields)

  __convertFields: ->
    @fields = DynamicFields.build(@fields)

  findField: (fieldName) ->
    @containerFields.findField(fieldName)

  @build: (field, containerFields) ->
    if field.__wrapper
      field
    else
      f = Object.assign(new DynamicFieldGroup(containerFields), field)
      f.__convertFields()
      window.Vue.observable(f)


export default DynamicFieldGroup
