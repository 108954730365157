











import CommonMixin from "./mixins/common"

import SimpleView from './coauthors/simple'
import CoefficientView from './coauthors/coefficient'

export default {
  mixins: [CommonMixin]

  components: {SimpleView, CoefficientView}

  methods: 
    coauthorsChanged: (coauthors)->
      @fieldValue = coauthors

  computed:
    inputId: ->
      "#{@formNamespace}_pim_idea_coauthor_ids"

    viewComponent: ->
      if @field.coefficient
        'CoefficientView'
      else
        'SimpleView'

    maxAmount: ->
      @field.max_amount
}
