





















import DynamicFieldMixin from "../mixins/dynamic_field"
import RedactorResetMixin from "../../../forms/mixins/redactor_reset"

TEXTAREA = 'textarea'

export default {
  mixins: [DynamicFieldMixin, RedactorResetMixin]

  mounted: ->
    @initAutoSize()
    @initRedactor() if @viewAsFormatted

  computed:
    viewAsTextarea: ->
      @field.view_as == TEXTAREA

    viewAsFormatted: ->
      @viewAsTextarea && @field.formatted

    viewAsOneLine: ->
      @field.view_as != TEXTAREA

    redactorOptions: ->
      o = @field.redactorOptions || {}
      Object.assign({}, o, {
        changeCallback: @redactorChangeCallbackHandler.bind(@)
      })

  methods:
    initRedactor: ->
      return unless @viewAsFormatted

      handler = =>
        document.createRedactor($(@$refs.redactor), @redactorOptions)

      setTimeout(handler.bind(@), 500)

    inputReset: ->
      @redactorReset()

    initAutoSize: ->
      return if @viewAsFormatted

      autosize(@$refs.redactor)

    redactorChangeCallbackHandler: (value) ->
      if(@isRedactorEmptyValue(value))
        @fieldValue = ''
      else
        @fieldValue = value

  watch:
    fieldValue: ->
      @checkSwearWords()
}
