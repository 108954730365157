f = ->
  console.log('implement')

if navigator.appVersion.match(/MSIE\s+\d+/)
  f = ->
    r = Math.random().toString(32).replace(/[^a-z0-9]+/g, '');
    if r.match(/^\d/)
      prefix =  Math.random().toString(32).replace(/[^a-z]+/g, '').substr(0, 5)
      r = "#{prefix}#{r}"
    r
else
  f = require('unique-string')

export default f
