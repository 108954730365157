






































import uniqueString from '../common/random_string'

import FormPartRequired from './form_part_required'
import FormPartErrors from './form_part_errors'

export default {
  components: {FormPartRequired, FormPartErrors}

  data: ->
    inputId: uniqueString()

  props:
    required:
      type: Boolean
      default: false

    checkSwearWords:
      type: Boolean
      default: false

    requiredTitle:
      type: String
      default: null

    requiredMark:
      type: String
      default: null

    label:
      type: String
      default: ''

    description:
      type: String
      default: ''

    hintEnabled:
      type: Boolean
      default: true

    hint:
      type: String
      default: null

    labelClasses:
      type: Object
      default: ->
        {}

    containerClasses:
      type: Object
      default: ->
        {}

    classSpace:
      type: Boolean
      default: false

    classAlignCenter:
      type: Boolean
      default: false

    errorMessages:
      type: [String, Array]
      default: ->
        null



  computed:
    _containerClass: ->
      r = {
        required: @required
        optional: !@required
        'has-error': @hasError
        "input-group--space": @classSpace
        "input-group--align-center": @classAlignCenter
      }
      Object.assign(r, @containerClasses)

    containerLabelClass: ->
      c = Object.assign({}, @labelClasses)
      c.required = @required
      c

    hasError: ->
      @_errorMessages? && @_errorMessages.length > 0

    _errorMessages: ->
      if Array.isArray(@errorMessages)
        @errorMessages
      else if @errorMessages? && @errorMessages.trim() != ''
        [@errorMessages]
      else
        []
}
