FormDataMixin = {
  data: ->
    actionUrl: ''
    advisesUrl: ''
    projectId: null
    fields: []
    formId: 'pim-idea-form'
    category: false
    formNamespace: ''
    formClass: ''
    crsfParam: ''
    crsfToken: ''
    groupsAsSteps: false
    ideaCategoryId: null
    stageAllowAddIdea: true
    mode: 'new'
    dynamicFieldNamespace: 'Idea'
    canSaveDraft: false
}

export default FormDataMixin
