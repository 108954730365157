



















import FormDataMixin from "./mixins/form_data"

import FormIdea from './form'

export default {
  mixins: [FormDataMixin]
  
  components: {FormIdea}

  computed:
    formIdeaClass: (category) ->
      c = {
        "#{@formClass}": @formClass
        'pim-idea-form__steps': @groupsAsSteps
      }

      classes = Object.keys(c).filter((k) -> c[k]).join(' ')
}
