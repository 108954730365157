













import DynamicFieldMixin from "../../mixins/dynamic_field"

import StyleSelect from '../../../../../components/common/styleselect'

export default {
  mixins: [DynamicFieldMixin]

  components: {StyleSelect}

  computed:
    fieldValues: ->
      ((@field.values || {})[@$pageLang()] || [])

    otherLabel: ->
      @$I18n('dynamic_fields.collection.other_value')

    styleSelectItems: ->
      items = []
      @fieldValues.forEach (fieldValue) ->
        f =
          label: fieldValue
          value: fieldValue
        items.push f

      if @field.has_other_value
        items.push {
          label: @otherLabel,
          value: 'other:'
        }
      items
}
