




























































import DynamicFieldMixin from "../../mixins/dynamic_field"

export default {
  mixins: [DynamicFieldMixin]

  mounted: ->
    if !@fieldValue? || @fieldValue.toString() == ''
      if @field.required
        @fieldValue = @field.rating_from
      else
        @fieldValue = ''

  computed:
    scale: ->
      [(@field.rating_from)..(@field.rating_to)]

  methods:
    sorterItemClass: (sorterIndex) ->
      active: sorterIndex.toString() ==  @field.value.toString()

    generateSorterInputName: (sorterIndex) ->
      @generateInputId(beforeField: sorterIndex.toString())

    generateKeyForSorter: (sorterIndex, type) ->
      "#{type}##{sorterIndex}"

    sorterItemClick: (sorterIndex, $event) ->
      if Number(@field.value) == sorterIndex && !@field.required
        @field.value = ''
      else
        @field.value = sorterIndex

    scaleLabel: (sorterIndex) ->
      @field.labels[sorterIndex]

    dropValue: ->
      @field.value = ""
}
