













import CommonMixin from "./mixins/common"
import FieldComponentMixin from '../../forms/mixins/field_components'

import ModelDynamicFields from "../libs/dynamic_fields"

export default {
  mixins: [CommonMixin, FieldComponentMixin]

  props:
    dynamicFieldNamespace:
      type: String
      default: ''

  methods:
    getFields: ->
      ModelDynamicFields.build(@field.fields).getFields()
}
