











import DynamicFieldMixin from "../mixins/dynamic_field"

export default {
  mixins: [DynamicFieldMixin]

  mounted: ->
    @initUserSelect()

  methods:
    initUserSelect: ->
      @appendOptionToSelect()

      new window.Agiki.Common.Select2RemoteInput($(@$refs.userSelect), @userSelectOptions())

    appendOptionToSelect: ->
      return unless @field.value_label?
      return unless @field.value?

      return if @field.value_label.toString() == ''

      element = document.createElement('option');
      element.setAttribute('value', @fieldValue)
      element.innerText = @field.value_label

      @$refs.userSelect.appendChild(element)

    userSelectOptions: ->
      url: @field.url
}
