import DynamicFieldMixin from "./dynamic_field"

CommonFile = {
  mixins: [DynamicFieldMixin]

  mounted: ->
    @initFileUpload()

  computed:
    fileMaxCount: ->
      @field.file?.maxCount || null

    fileMaxByteSize: ->
      @field.file?.maxByteSize || 10485760

    fileUploadUrl: ->
      @field.file?.uploadUrl || ''

    fileAllowedFileTypes: ->
      @field.file?.allowedFileTypes || []

    fileDropfield: ->
      @field.file?.dropfield || false

    fileMultiple: ->
      @field.file?.multiple || false

  methods:
    initFileUpload: ->
      return unless @$refs.fileUpload?

      @renderFilesElement()

      handler = =>
        asyncFileUpload = new window.Agiki.Common.AsyncFileUpload($(@$refs.fileUpload))
        asyncFileUpload.registerDoneEvent(@asyncFileUploadDoneHandler.bind(@))
        asyncFileUpload.registerFileInputRemoveEvent(@asyncFileUploadFileInputRemoveHander.bind(@))

      setTimeout(handler.bind(@), 100)

    renderFilesElement: ->
      return unless @field.attachments?

      @field.attachments.forEach (attachment) =>
        @renderFileElement(attachment)

    asyncFileUploadDoneHandler: (payload) ->
      @fieldValue ||= []
      @fieldValue.push(payload.result.id)

    asyncFileUploadFileInputRemoveHander: (payload) ->
      @fieldValue ||= []
      index = @fieldValue.find (v) ->
        parseInt(v) == parseInt(payload.id)
      @fieldValue.splice(index, 1) if index != -1

    renderFileElement: (attachment) ->
      input_file_file = document.createElement('div')
      input_file_file.classList.add('input-file__file')

      icon = document.createElement('i')
      icon.classList.add('icon', 'icon--margin-right', 'input-file__icon')
      input_file_file.appendChild(icon)

      svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      icon.appendChild(svg)

      svg_use = document.createElementNS("http://www.w3.org/2000/svg", "use");
      svg_use.setAttributeNS(null, "xlink", "http://www.w3.org/1999/xlink")
      svg_use.setAttributeNS(null, "href", "#icon-insert-drive-file")
      svg.appendChild(svg_use)

      input_file_filename = document.createElement("div")
      input_file_filename.classList.add("input-file__filename")
      input_file_filename.innerText = attachment.fileName
      input_file_file.appendChild(input_file_filename)

      input_file_filename = document.createElement("a")
      input_file_filename.classList.add('input-file__action')
      input_file_filename.setAttribute("href", 'javascript:void(0)')
      input_file_filename.innerText = "Удалить файл"
      input_file_file.appendChild(input_file_filename)

      input = document.createElement("input")
      input.setAttribute("type", "hidden")
      input.setAttribute("name", @inputName)
      input.setAttribute("value", attachment.id.toString())
      input_file_file.appendChild(input)

      input_container = @$el.querySelector('.input-file')
      input_container = @$el unless input_container?

      console.log(input_container)

      input_container.appendChild(input_file_file)
}

export default CommonFile
