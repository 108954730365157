


































import DynamicFieldMixin from "../../mixins/dynamic_field"

export default {
  mixins: [DynamicFieldMixin]

  mounted: ->
    # @fieldValue = [] if !@fieldValue? || @fieldValue.toString() == ''
    if !@fieldValue? || @fieldValue.toString() == ''
      @fieldValue = []
      for list, index in @field.lists
        @fieldValue[index] = list.items[0].id

  methods:
    generateListItemInputId: (listIndex, item) ->
      @generateInputId(afterField: "#{listIndex}_items_#{item.id}")

    generateListItemInputName: (listIndex, item) ->
      @generateInputName(afterField: [listIndex, 'items'])
}
