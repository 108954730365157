








































import FlattenView from '../../../components/forms/flatten_view'
import StepView from '../../../components/forms/step_view'

import axios from 'axios'

import UpdateSimilarBlockMixin from "./form_fields/mixins/update_similar_block"
import DynamicFieldsModel from "../../../components/forms/mixins/dynamic_fields_model"

import ModelDynamicFields from "../../../components/dynamic_fields/libs/dynamic_fields"

MIXIN_DATA = {
  data: ->
    showForm: false
    allFieldsValid: true
    allFieldsChecked: true      
}

export default {
  mixins: [MIXIN_DATA, UpdateSimilarBlockMixin, DynamicFieldsModel]

  components: {FlattenView, StepView}

  props:
    actionUrl:
      type: String
      default: ''
    advisesUrl:
      type: String
      default: ''
    projectId:
      type: [String, Number]
      default: null
    formId:
      type: String
      default: 'pim-idea-form'
    category:
      type: Boolean
      default: false
    formNamespace:
      type: String
      default: ''
    formClass:
      type: String
      default: ''
    crsfParam:
      type: String
      default: ''
    crsfToken:
      type: String
      default: ''
    groupsAsSteps:
      type: Boolean
      default: false
    ideaCategoryId:
      type: [String, Number]
      default: null
    stageAllowAddIdea:
      type: Boolean
      default: true
    mode:
      type: String
      default: 'new'
    dynamicFieldNamespace:
      type: String
      default: ''
    canSaveDraft:
      type: Boolean
      default: false
    isObjectDraft:
      type: Boolean
      default: false

  computed:
    viewComponent: ->
      if @isModeNew && @groupsAsSteps && @hasFieldsGroups
        'StepView'
      else
        'FlattenView'

    isModeNew: ->
      @mode == 'new'

    isModeEdit: ->
      @mode == 'edit'

    formClassAll: ->
      r = @formClass
      if @isModeEdit
        r = r.replace(/(\s+|^)hide((\s+|$))/, ' ')
      r

    formMethod: ->
      if @isModeNew
        'post'
      else
        'patch'

    isCanSaveDraft: ->
      if @isModeNew
        @canSaveDraft
      else
        @canSaveDraft && @isObjectDraft

    notValidFields: ->
      @fieldsFlatten.filter (f) ->
        f.valid == false

    notCheckedFields: ->
      @fieldsFlatten.filter (f) ->
        f.checked == false

  beforeMount: ->
    @beforeMountDynamicFieldsModel()

  mounted: ->
    @registerChangeContainerOffsetHeight()

  beforeDestroy: ->
    @formResetState()
    @destroyChangeContainerOffsetHeight()

  methods:
    clickCancelButton: ->
      @formResetState()
      @showForm = false

      @$emit('formCancelled')

    clickSubmitButton: ->
      if @allFieldsValid && @allFieldsChecked
        @childrenSubmitStart()

        axios.request(@axisRequest()).then(@axisSuccess.bind(@))
      else
        console.log @allFieldsValid

    clickSaveDraftButton: ->
      @childrenSubmitStart()

      axios.request(@axisRequest(true)).then(@axisSuccess.bind(@))

    axisRequest: (isDraft)->
      url: @actionUrl
      method: @formMethod
      data: @formData(isDraft)

    axisSuccess: (response) ->
      data = response.data
      @resetErrorMessages()
      if data.status? && data.status == 'success'
        @formReset()
        if @isModeNew
          @formFadeOut(data)
        else
          @formFadeOut(data)
          window.Agiki.Common.Article.ajaxFormEditHandler($(@$refs.form))(null, data)

        @$emit('formSubmitted')
      else if data.status? && data.status == 'error'
        @assignErrorsToField(data.errors)
        @childrenSubmitFinish()

    formData: (isDraft)->
      # TODO: надо убрать привязку к jquery. В ней просто удобный сериализатор формы
      data = $(@$refs.form).serializeArray()
      newData = new FormData();
      data.forEach (i) ->
        newData.append(i.name, i.value)
      newData.append('save_draft', 'save_draft') if isDraft
      newData

    formResetState: ->
      @resetErrorMessages()
      @formReset()

    formReset: ->
      @$refs.form.reset()
      @__fieldsModel.resetValueToInitial()
      @inputReset(@$children)

    inputReset: (children) ->
      children.forEach (child) =>
        child.inputReset() if child.inputReset
        @inputReset(child.$children)

    childrenSubmitStart: ->
      @$children.forEach (child) ->
        child.submitStart() if child.submitStart

    childrenSubmitFinish: ->
      @$children.forEach (child) ->
        child.submitFinish() if child.submitFinish

    formFadeOut: (data) ->
      # TODO: надо убрать привязку к jquery.
      $form = $(@$refs.form)
      $form.fadeOut 500, () =>
        document.____formLinkTogglePlus($form)                 # Переключение кнопки формы
        document.____similarIdeasSearchToggle($form, 'hide')   # Скрываем список похожих идей
        $('.js-show-idea-form:hidden').each ->                 # Показываем кнопки создания идеи, если есть скрытые
          $(@).show()
        if $('.js-pim-filter-control[data-filter-param="order"]').length == 0
          $('.pim-top').replaceWith(data.html_project_top) # Перезагрузка заголовка списка идей, если добавлена первая идея
        $date = new Date()
        $sec =  20 # Время жизни в минутах
        $date.setTime($date.getTime() + ($sec * 1000))
        window.Cookies.set("article-id-" + data.id, true, {expires: $date})
        # Сортировка идей "сначала новые" после добавления идеи
        $('.js-pim-filter-control[data-filter-param="order"]').val('new').change()
        # Скролл к началу списка идей
        if $('.pim-top').length != 0
          $('html, body').animate { scrollTop: $('.pim-top').offset().top - parseInt($('header .header').height())}, 500
        @childrenSubmitFinish()
      if data.drafts_html
        document.____initUpdateDraftsList(data.drafts_html)

    assignErrorsToField: (errors) ->
      return unless errors?
      Object.keys(errors).forEach (fieldName) =>
        fieldErrorInfo = errors[fieldName]
        if fieldName == 'dynamic_field_values'
          fieldErrorInfo = @assignErrorsToField(fieldErrorInfo)
        else
          fieldErrorMessages = if Array.isArray(fieldErrorInfo.messages)
            fieldErrorInfo.messages
          else
            [fieldErrorInfo.messages[fieldName]]
          @__fieldsModel.setErrorMessages(fieldName, fieldErrorMessages)

    resetErrorMessages: ->
      @__fieldsModel.resetErrorMessages()

  watch:
    notValidFields: ->
      @allFieldsValid = (@notValidFields.length == 0)
    
    notCheckedFields: ->
      @allFieldsChecked = (@notCheckedFields.length == 0)

}
