InputIdNameGeneratorsMixin = {
  methods:
    # payload:
    #   beforeField: ''
    #   afterField: ''
    #   fieldName: null
    generateInputId: (payload = {}) ->
      parts = [@formNamespace, "#{@fieldNamePrefix}_dynamic_field_values"]
      parts.push(payload.beforeField.toString()) if payload.beforeField?
      if @field?.name
        parts.push(@field.name)
      else if payload.fieldName?
        parts.push(payload.fieldName)
      parts.push(payload.afterField.toString()) if payload.afterField?
      parts.join('_')

    generateInputName: (payload = {}) ->
      parts = ['dynamic_field_values']
      parts.push(...payload.beforField) if payload.beforField?
      if payload.fieldName?
        parts.push(payload.fieldName)
      else if @fieldName?
        parts.push(@fieldName)
      else if @field?.name
        parts.push(@field.name)            
      parts.push(...payload.afterField) if payload.afterField?

      parts.push('') if @arrayType?

      part = parts.map((p) -> ("[#{p}]")).join('')
      if @fieldNamePrefix?
        prefix = @fieldNamePrefix
      else if payload.fieldNamePrefix?
        prefix = payload.fieldNamePrefix
      else
        prefix = ''

      "#{prefix}#{part}"
}

export default InputIdNameGeneratorsMixin
