





































































import FormDataMixin from "./mixins/form_data"

import FormIdea from './form'

DATA_MIXIN = {
  data: ->
    categories: []
    selectedCategory: null
}

export default {
  mixins: [FormDataMixin, DATA_MIXIN]

  components: {FormIdea}

  computed:
    containerClass: ->
      'pim-idea-form--with-similar-ideas': @selectedCategory?

    isShowIdeaForm: ->
      @selectedCategory?

    isShowCategoriesSelector: ->
      !@selectedCategory?

    labelSubmitDisabledByStage: ->
      @$I18n().activemodel.attributes['pim/project'].idea_adding_disabled_by_user_fields

  methods:
    buttonCategoryClass: (category) ->
      {
        "pim-button--idea-category-#{category.id}-brand": true
      }

    clickCategory: (category, $event) ->
      if @selectedCategory?.id == category.id
        @selectedCategory = null
      else
        @selectedCategory = category

    buttonText: (category) ->
      return '+' unless @selectedCategory?
      if category.id == @selectedCategory.id
        "-"
      else
        '+'

    formIdeaClass: (category) ->
      c = {
        'pim-idea-form': true
        'pim-idea-form__steps': @groupsAsSteps
        'new_pim_idea': true
      }

      Object.keys(c).filter((k) -> c[k]).join(' ')

    formVueKey: (category) ->
      "form_#{category.id}"

    formCancelledHandler: ->
      @selectedCategory = null

    formSubmittedHandler: ->
      @selectedCategory = null

    clickSelectedCategory: ->
      @selectedCategory = null

    clearDataSimilar: (container) ->
      dataSimilar = container.querySelector('[data-similar]')
      return unless dataSimilar?
      dataSimilar.style.display = 'none'
      list = dataSimilar.querySelector('#js-similar-ideas-list')
      return unless list?

      while (list.firstChild)
        list.removeChild(list.firstChild);

    newIdeaFromCategories: ->
      @$I18n().pim.ideas.messages.new_idea_from_categories

  watch:
    selectedCategory: (newValue, oldValue) ->
      container = @$el.closest('.pim-idea-form-container')
      return unless container?

      if newValue
        container.classList.add('pim-idea-form-container--form-opened')
        container.querySelector('[data-similar]').style.display = 'block'

        @$nextTick =>
          form = @$el.querySelector('form')
          return unless form?
          window.____updateSimilarIdeas($(form))
      else
        container.classList.remove('pim-idea-form-container--form-opened')
        @clearDataSimilar(container)


}
