






export default {
  props:
    title:
      type: String
      default: null

    mark:
      type: String
      default: null

    display:
      type: Boolean
      default: false

  computed:
    titleDisplay: ->
      if @title?
        @title
      else
        @$I18n('simple_form.required.text')

    markDisplay: ->
      if @mark?
        @mark
      else
        @$I18n('simple_form.required.mark')

}
