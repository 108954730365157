













import CommonMixin from "../dynamic_fields/form/mixins/common"
import FieldComponentMixin from './mixins/field_components'

import DynamicFields from "../dynamic_fields/libs/dynamic_fields"

export default {
  mixins: [CommonMixin, FieldComponentMixin]

  props:
    projectId: [Number, String]
    formNamespace: String
    category: Boolean
    step: Object
    isStepShow:
      type: Boolean
      default: false

  computed:
    stepLabel: ->
      @step.label[@$pageLang()]

    fields: ->
      DynamicFields.build(@step.fields).getFields()

  methods:
    test: ->
      console.log('test')


}
