






























import CommonMixin from "./mixins/common"

export default {
  mixins: [CommonMixin]

  computed:
    inputName: ->
      "#{@fieldNamePrefix}[expertise_status]"

  mounted: ->      
    existing_keys = @field.statues.filter (s) =>
       s.key == @fieldValue
    if existing_keys.length == 0
      @fieldValue = @field.statues[0].key
      
  methods:
    generateInputIdForRadio: (status) ->
      "#{@fieldNamePrefix}_expertise_status_#{status.key}"

    getClassesFroInput: (status) ->
      {
        required: @field.required
      }
}
